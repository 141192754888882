<script>
  import { _ } from "svelte-i18n"
  import LoadingOverlay from "/@/control/LoadingOverlay.svelte"
  import { media } from "/@/layout"
  import { HeaderButton } from "/@/layout/header"
  import { asyncConfirm } from "/@/layout/modal"
  import { logout } from "/@/util"
  import { headerNavLogoutButton } from "/@/util/user-analytics"

  let promise = null
  export let component = HeaderButton

  function handleLogOut() {
    asyncConfirm("This will end your trade session, are you sure?", {
      height: "30vh",
      width: $media.mobile ? "75vw" : "40vw",
    }).then((shouldProceed) => {
      if (!shouldProceed) return
      promise = logout()
    })
  }
</script>

<svelte:component
  this={component}
  class="logout"
  icon="LogOut"
  on:click={handleLogOut}
  userAnalyticsTag={headerNavLogoutButton}
>
  {$_("content.trading.logoutButton")}
</svelte:component>
{#await promise}
  <LoadingOverlay text={$_("content.trading.loading.loggingOut")} />
{/await}
