<script>
  import { _ } from "svelte-i18n"
  import { HeaderButton } from "/@/layout/header"

  import {
    helpCenterPanelVisible,
    leftSidebarCollapsed,
    media,
    rightSidebarCollapsed,
    rightSidebarVisible,
  } from "/@/layout"

  export let component = HeaderButton

  const HELP_BUTTON_ID = "ZVA-help-button"

  function clickHandler(event) {
    event.stopPropagation()
    if ($media.mobile) {
      $leftSidebarCollapsed = true
    }
    $helpCenterPanelVisible = !$helpCenterPanelVisible
    if ($helpCenterPanelVisible) {
      $rightSidebarCollapsed = false
      $rightSidebarVisible = true
    }
  }
</script>

<svelte:component
  this={component}
  class="help-button"
  icon="Help"
  id={HELP_BUTTON_ID}
  on:click={clickHandler}
>
  {$_("content.trading.helpButton")}
</svelte:component>
