<script>
  import { MobileHeader } from "/@/layout/header"
  import IgTastyLogo from "/@/layout/ig/logo/IgTastyLogo.svelte"

  export let navItems
</script>

<MobileHeader {navItems}>
  <IgTastyLogo height="50%" />
</MobileHeader>
