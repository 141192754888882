<script>
  import { derived } from "svelte/store"
  import AdminHeaderRouter from "./AdminHeaderRouter.svelte"
  import AdminMainContentRouter from "./AdminMainContentRouter.svelte"
  import HeaderRouter from "./HeaderRouter.svelte"
  import MainContentRouter from "./MainContentRouter.svelte"
  import { configureServiceWorker } from "./configure-service-worker"
  import { initSession, isPrivacyMode } from "./util"
  import { LoadingOverlay } from "/@/control"
  import { Layout } from "/@/layout"
  import {
    getStoredUsername,
    getUserExternalId,
    IG_APP,
    isAdmin,
  } from "/@lib/shared"

  configureServiceWorker()

  const promise = initSession().then(() => {
    // NOTE: [DK] Push the external_user_id to the data layer. Whenever the user becomes/is authenticated.
    window.dataLayer.push({
      "tasty-user-id": getUserExternalId(),
      version: 2,
    })
  })

  const windowTitle = derived(
    isPrivacyMode,
    ($isPrivacyMode) => {
      const username = getStoredUsername()
      if (IG_APP) {
        return "US options and futures"
      }
      if (!username || $isPrivacyMode || $isPrivacyMode === null) {
        return "tastytrade"
      } else {
        return `${isAdmin() ? "ADMIN " : ""}${username} on tastytrade`
      }
    },
    "tastytrade"
  )

  const favicon = IG_APP ? "/ig/favicon" : "/favicon"
  const icon192 = IG_APP ? "/ig/ig-192.png" : "/tt-192.png"
</script>

<svelte:head>
  <title>{$windowTitle}</title>
  <link
    rel="alternate icon"
    href={`${favicon}.ico`}
    type="image/png"
    sizes="16x16"
  />
  <link rel="mask-icon" href={`${favicon}.svg`} color="rgb(41,45,41)" />
  <link rel="icon" href={icon192} type="image/png" />
  <link rel="apple-touch-icon" href={icon192} type="image/png" />
  <link rel="apple-touch-startup-image" href={icon192} type="image/png" />
</svelte:head>

{#await promise}
  <LoadingOverlay />
{:then _value}
  <Layout>
    {#if isAdmin()}
      <AdminHeaderRouter />
      <AdminMainContentRouter />
    {:else}
      <HeaderRouter />
      <MainContentRouter />
    {/if}
  </Layout>
{/await}
