import { Writable, writable } from "svelte/store"
import { fetchAll } from "./contentstack"

const IG_CONTENT_TYPE_KEY = "tasty_ig"

export enum IgContent {
  IG_CAM_Footer_Disclaimer,
  IG_CAM_Summary_Copy,
  IG_risk_monitoring_expiration_risk_monitoring_disclaimer,
  IG_Risk_Monitoring_Reset_PDT_Status_Classified_as_PDT,
  IG_risk_monitoring_reset_PDT_status_not_PDT,
  IG_Risk_Monitoring_Reset_PDT_Status_Note,
  IG_Risk_Monitoring_Risk_Monitoring_Description,
  IG_Trading_Preferences_Enable_Portfolio_Margin,
  IG_Trading_Preferences_Futures,
  IG_Trading_Preferences_Intraday_Futures_Margin,
}

export const igContent: Writable<Map<string, string> | null> = writable(
  new Map<string, string>()
)

export async function loadIgContent() {
  const toMapEntry = (entry: { text: string; identifier: string }) => [
    entry.identifier,
    entry.text,
  ]
  const content = await fetchAll(IG_CONTENT_TYPE_KEY, toMapEntry)
  igContent.set(content)
}
