<script>
  import darkThemeIGLogo from "/@/assets/image/tastytrade_ig_dark.svg"
  import lightThemeIGLogo from "/@/assets/image/tastytrade_ig_light.svg"
  import { isDark } from "/@/theme"
  import userAnalytics, {
    headerNavTastyworksLogoImage,
  } from "/@/util/user-analytics"

  export let height = null
  let cssClass = ""
  export { cssClass as class }

  $: getSrc = $isDark ? darkThemeIGLogo : lightThemeIGLogo
</script>

<img
  use:userAnalytics={headerNavTastyworksLogoImage}
  class="ig-tastyworks-logo my-small {cssClass}"
  src={getSrc}
  alt="tastytrade"
  style:height
/>
