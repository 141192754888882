<script>
  import { cn } from "@tastyworks/ui-library"
  import { fade } from "svelte/transition"

  let className = ""
  export { className as class }
  export let fadeOptions = { duration: 0 } // effectively disabled
</script>

<div
  transition:fade={fadeOptions}
  class={cn(
    "overlay absolute inset-0 z-1 flex flex-col items-center justify-center gap-medium bg-black opacity-80",
    className
  )}
/>
