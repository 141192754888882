<script lang="ts">
  export let rowIndex = -1
  export let isActive = false
  export let element = "div"
  export let onClick = undefined
  export let cols = 3

  let cssClass = ""
  export { cssClass as class }
</script>

<svelte:element
  this={element}
  class="
    table-body-row
    grid
    grid-cols-{cols}
    gap-x-small
    px-medium
    py-double-extra-small
    hover:bg-general-primary-background
    {cssClass}"
  class:active={isActive}
  class:pl-small={isActive}
  class:border-l-4={isActive}
  class:border-l-header-tab-underline-active={isActive}
  class:bg-general-primary-background={isActive}
  role="row"
  aria-rowindex={rowIndex}
  on:click={onClick}
  tabindex="-1"
>
  <slot />
</svelte:element>
