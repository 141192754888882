<script lang="ts">
  import IgMobileHeader from "./IgMobileHeader.svelte"
  import { isDashboard, isTrading } from "/@/layout"
  import { HamburgerButton, HeaderBar, HeaderNav } from "/@/layout/header"
  import IgCamAccountSelector from "/@/layout/ig/account-selector/IgCamAccountSelector.svelte"
  import IgTastyLogo from "/@/layout/ig/logo/IgTastyLogo.svelte"
  import media from "/@/layout/media"
  import HelpButton from "/@/logged-in/HelpButton.svelte"
  import { NavItem } from "/@/logged-in/routes"

  export let navItems: NavItem[]
  export let showMenuButton = true
</script>

<HeaderBar>
  {#if $media.mobile || ($media.tablet && !$isTrading)}
    <IgMobileHeader {navItems} />
  {:else}
    <div
      class="h-full flex-row {$isDashboard
        ? 'inline-flex flex-row-reverse'
        : 'contents'}"
    >
      <div
        style:width="var(--left-sidebar-collapsed-width)"
        style:min-width="var(--left-sidebar-collapsed-width)"
        class:md:mr-2={!$isDashboard}
        class:ml-0.5={!$media.mobile}
        class="flex h-full items-center justify-center align-middle"
      >
        <HamburgerButton {showMenuButton} />
      </div>
      <IgTastyLogo
        height="50%"
        class="mr-extra-large {$isDashboard && 'ml-medium'}"
      />
    </div>
    <HeaderNav {navItems} />
    <slot name="left">
      <div class="flex-1" />
    </slot>
    <slot name="right">
      <HelpButton />
      <IgCamAccountSelector />
    </slot>
  {/if}
</HeaderBar>
