<script>
  import { LoadingOverlay } from "/@/control"
  import { _ } from "svelte-i18n"
  import { onMount } from "svelte"

  export let text = ""
  // svelte-ignore unused-export-let
  export let params = undefined

  async function subscribeToStatusMessage() {
    // dynamic imports prevent premature module loading
    const [{ default: boulePropertyStore }, { twSessionManager }] =
      await Promise.all([
        import("/@/boule/control/boule-property-store.js"),
        import("/@lib/boulangerie"),
      ])

    return boulePropertyStore(
      twSessionManager.loadingStatusMessageKeyProperty()
    ).subscribe((messageKey) => (text = $_(`content.${messageKey}`)))
  }

  onMount(() => {
    const promise = subscribeToStatusMessage()
    return () => promise.then((unsub) => unsub())
  })
</script>

<LoadingOverlay {text} />
