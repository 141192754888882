<script lang="ts">
  import { link, location } from "svelte-spa-router"
  import active from "svelte-spa-router/active"
  import { slide } from "svelte/transition"
  import Backdrop from "/@/control/Backdrop.svelte"
  import Popover from "/@/layout/popover/Popover.svelte"
  import { NavItem } from "/@/logged-in/routes"
  import userAnalytics from "/@/util/user-analytics"

  const duration = 250

  export let navItems: NavItem[]

  let navMenuCollapsed = true

  $: activeSection = $location.split("/")[1]
</script>

{#if !navMenuCollapsed}
  <Backdrop class="top-12" fadeOptions={{ duration }} />
{/if}
<div class:hidden={!activeSection}>
  <button
    class="mr-extra-small flex flex-row items-center justify-end rounded-[4px] bg-button-default px-extra-small"
    on:click={() => (navMenuCollapsed = !navMenuCollapsed)}
  >
    <span class="font-small-600 !capitalize">{activeSection}</span>
    <span
      class="icon font-x-large-600 ml-2"
      class:tw-icon-Arrow-Up={!navMenuCollapsed}
      class:tw-icon-Arrow-Down={navMenuCollapsed}
    />
  </button>
  {#if !navMenuCollapsed}
    <Popover
      class="h-min bg-general-tertiary-surface"
      inset="calc(1.5 * var(--vertical-meter)) 0 0 0"
      on:close={() => {
        // FIXME: Hack around already-open trigger-click with a sleep.
        setTimeout(() => (navMenuCollapsed = true), 10)
      }}
    >
      <div transition:slide={{ duration }}>
        {#each navItems as { glob, href, label, analyticsId }}
          <a
            class="
            font-small-600
            block
            w-full
            border-b
            border-divider-horizontal
            p-2
            pl-medium
            text-left
            !capitalize
            hover:text-navigation-page-label-active"
            use:active={{
              className: [
                "text-navigation-page-label-active",
                "hover:text-general-navigation-active",
                "border-b-2",
                "border-b-header-tab-underline-active",
              ].join(" "),
              path: glob,
            }}
            {href}
            use:link
            use:userAnalytics={analyticsId}
          >
            {label}
          </a>
        {/each}
      </div>
    </Popover>
  {/if}
</div>
