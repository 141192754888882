<script lang="ts">
  import IgAccountsTableHeaderCell from "./IgAccountsTableHeaderCell.svelte"

  export let headers: string[] = []
</script>

<div
  class="
    table-header
    grid
    grid-cols-{headers.length}
    font-x-small-400
    gap-x-small
    px-medium
    text-general-secondary-text"
  role="rowgroup"
>
  <slot>
    {#each headers as header, i}
      <IgAccountsTableHeaderCell
        title={header}
        class={i === headers.length - 1 ? "text-right" : ""}
      >
        {header}
      </IgAccountsTableHeaderCell>
    {/each}
  </slot>
</div>
