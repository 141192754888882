<script lang="ts" context="module">
  import { customer } from "/@/util/customer"

  const selectorOpen = writable(false)
</script>

<script lang="ts">
  import { Customer } from "@tastyworks/tastyworks-api"
  import { writable } from "svelte/store"
  import IgCamAccountSelectorPopover from "./IgCamAccountSelectorPopover.svelte"
  import {
    currentAccount,
    currentAccountNumber,
  } from "/@/account-management/store/account"
  import { asyncPopover } from "/@/layout/popover"
  import { px } from "/@/util"

  let cssClass = "ml-2 h-full w-48 border-l"
  export { cssClass as class }
  export let sidebar = false // opens from top-right normally, bottom-left in sidebar

  let windowHeight, windowWidth, rootElem, navbarHeight

  async function displayPopover() {
    if ($selectorOpen) {
      return
    }
    const { right, top } = rootElem.getBoundingClientRect()

    const inset = sidebar
      ? `auto auto ${px(navbarHeight)} 0`
      : `${px(navbarHeight)} ${px(windowWidth - right)} ${px(
          windowHeight - top
        )} auto`

    $selectorOpen = true

    // would use `right` to set exact width, but tailwind prunes a dynamic class created here
    // component should only be in sidebar on mobile, so this isn't horribly problematic
    await asyncPopover(IgCamAccountSelectorPopover, {
      inset,
      class: sidebar
        ? `w-[var(--left-sidebar-expanded-width-mobile)]`
        : "max-w-full",
    })

    // FIXME: Hack around already-open trigger-click with a sleep. Not sure
    //        there's a good solution to this. Popover's click-outside (close:
    //        null) fires before the trigger-click, so we always think we're
    //        closed.
    await new Promise((resolve, _reject) => setTimeout(resolve, 10))

    $selectorOpen = false
  }

  function getInitials(customer: Customer) {
    return customer?.firstName?.charAt(0) + customer?.lastName?.charAt(0)
  }
</script>

<svelte:window bind:innerHeight={windowHeight} bind:innerWidth={windowWidth} />
<div
  class="ig-cam-account-selector border-general-default hover:bg-general-secondary-surface {cssClass}"
  class:bg-general-secondary-surface={$selectorOpen}
  bind:this={rootElem}
  bind:clientHeight={navbarHeight}
>
  <button
    class="ig-cam-account-selector-button flex h-full w-full items-center justify-between"
    on:click={displayPopover}
  >
    <div class="flex h-full gap-x-double-extra-small pl-small">
      <div class="flex h-full items-center justify-center self-center">
        <div
          class="
              font-xx-small-500
              flex
              h-6
              w-6
              items-center
              justify-center
              rounded-full
              bg-toggle-switch-active
              text-general-inverted-text"
        >
          {getInitials($customer)}
        </div>
      </div>
      <div class="flex h-full w-28 flex-col items-start justify-center">
        <span class="font-x-small-500 w-full truncate text-left">
          {$currentAccount?.extAccountId
            ? `Account ID: ${$currentAccount?.extAccountId}`
            : $currentAccount?.nickname}
        </span>
        <span class="font-xx-small-500 w-full text-left">
          {`tt ID: ${$currentAccountNumber}`}
        </span>
      </div>
    </div>
    <span
      class="
          font-x-large-600
          mr-small
          flex
          h-full
          items-center
          justify-center
          self-center
          text-general-secondary-text"
      class:tw-icon-Arrow-Up={sidebar ? !$selectorOpen : $selectorOpen}
      class:tw-icon-Arrow-Down={sidebar ? $selectorOpen : !$selectorOpen}
    />
  </button>
</div>
