<script lang="ts">
  import { link } from "svelte-spa-router"
  import active from "svelte-spa-router/active"
  import { helpCenterPanelVisible } from "/@/layout"
  import { NavItem } from "/@/logged-in/routes"
  import userAnalytics from "/@/util/user-analytics"

  const LINE_HEIGHT =
    "calc(var(--vertical-meter) * 1.5 - var(--double-extra-small-spacing))"

  export let navItems: NavItem[]
  let cssClass = ""
  export { cssClass as class }

  function changingRoute() {
    $helpCenterPanelVisible = false
  }
</script>

<nav>
  <ul class="flex items-center gap-double-extra-small" id="header-nav-links">
    {#each navItems as { href, label, glob, analyticsId, id }}
      <li>
        <a
          class="
            font-small-600-caps
            inline-block
            h-full
            border-y-2
            border-transparent
            px-small
            hover:no-underline
            {cssClass}"
          {href}
          style:line-height={LINE_HEIGHT}
          {id}
          use:active={{
            className: [
              "text-navigation-page-label-active",
              "border-b-2",
              "border-b-header-tab-underline-active",
              "focus:border-b-transparent",
            ].join(" "),
            inactiveClassName: [
              "text-navigation-page-label-inactive",
              "hover:text-navigation-page-label-active",
              "focus:text-navigation-page-label-active",
            ].join(" "),
            path: glob,
          }}
          use:link
          use:userAnalytics={analyticsId}
          on:click={changingRoute}
        >
          {label}
        </a>
      </li>
    {/each}
  </ul>
</nav>
