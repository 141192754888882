<script lang="ts">
  import { SvelteComponent } from "svelte"
  import Router, {
    replace,
    RouteLoadedEvent,
    WrappedComponent,
  } from "svelte-spa-router"
  import wrap from "svelte-spa-router/wrap"
  import { captureException } from "@sentry/browser"
  import InitializationError from "./InitializationError.svelte"
  import { APP_ROOT_REDIRECT, ERROR, TRADING } from "./routes"
  import { loadAndSaveTradingSession } from "/@/logged-in/util"
  import TradingLoadingOverlay from "/@/trading/TradingLoadingOverlay.svelte"

  async function loadTradingLayout() {
    try {
      await loadAndSaveTradingSession()
      return import("/@/trading/TradingLayout.svelte")
    } catch (error) {
      captureException(error)
      replace("/error")
    }
  }

  function redirectHandler(event: RouteLoadedEvent) {
    switch (event.detail.route) {
      case APP_ROOT_REDIRECT.path:
        replace(TRADING.path)
        break
    }
  }

  const routes = new Map<
    string,
    typeof SvelteComponent<any> | WrappedComponent
  >([
    [APP_ROOT_REDIRECT.path, TradingLoadingOverlay],
    [
      TRADING.glob,
      wrap({
        asyncComponent: loadTradingLayout,
        loadingComponent: TradingLoadingOverlay,
        loadingParams: {
          text: "Loading Trading",
        },
        props: {
          agent: "other",
        },
      }),
    ],
    [ERROR.path, InitializationError],
  ])
</script>

<Router on:routeLoaded={redirectHandler} {routes} />
