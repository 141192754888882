<script lang="ts">
  let cssClass = ""

  export { cssClass as class }
  export let span: number = undefined
</script>

<div
  class="table-body-cell {cssClass}"
  role="cell"
  style:grid-column={span ? `span ${span} / span ${span}` : ""}
>
  <slot />
</div>
