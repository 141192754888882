<script lang="ts">
  import { isNilOrEmpty } from "@tastyworks/tastyworks-api"
  import { createEventDispatcher } from "svelte"
  import {
    accountsStore,
    currentAccountNumber,
  } from "/@/account-management/store/account"
  import IgCamTastyAccountsTable from "/@/layout/ig/account-table/IgCamTastyAccountsTable.svelte"
  import Popover from "/@/layout/popover/Popover.svelte"
  import { logout } from "/@/util"
  import { derived } from "svelte/store"

  export let inset = "auto"
  let cssClass = ""
  export { cssClass as class }
  const dispatch = createEventDispatcher()

  function close() {
    dispatch("close")
  }

  $: cashAccounts = derived(accountsStore, ($accountsStore) =>
    $accountsStore.values.filter((account) => account.isCash)
  )

  $: marginAccounts = derived(accountsStore, ($accountsStore) =>
    $accountsStore.values.filter((account) => account.isMargin)
  )
</script>

<Popover
  class="ig-cam-account-selector-popover w-[32rem] {cssClass}"
  on:close={close}
  on:click_outside={close}
  {inset}
>
  <div class="bg-general-secondary-surface">
    <div
      class="
          flex
          h-full
          max-h-[80vh]
          flex-col
          gap-y-medium
          overflow-auto
          border-b
          border-divider-horizontal
          pb-small
          pt-medium"
    >
      {#if !isNilOrEmpty($marginAccounts)}
        <IgCamTastyAccountsTable
          accounts={$marginAccounts}
          {currentAccountNumber}
          tableHeader="Margin - US options and futures"
        />
      {/if}
      {#if !isNilOrEmpty($cashAccounts)}
        <IgCamTastyAccountsTable
          accounts={$cashAccounts}
          {currentAccountNumber}
          tableHeader="Cash - US options and futures"
        />
      {/if}
    </div>
    <button
      type="button"
      class="w-full border-b border-divider-horizontal px-medium py-small text-left hover:bg-general-primary-background"
      on:click={() => window.open(import.meta.env.VITE_MY_IG_URL, "_self")}
    >
      My IG - Payments & Management
    </button>
    <button
      class="w-full px-medium pb-medium pt-small text-left hover:bg-general-primary-background"
      type="button"
      on:click={logout}
    >
      Log Out
    </button>
  </div>
</Popover>
