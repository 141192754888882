<div
  class="
    flex
    flex-row
    items-center
    justify-center
    gap-x-small
    overflow-hidden
  "
  style:grid-area="left-sidebar / left-sidebar / right-sidebar / right-sidebar"
>
  <div>
    <svg
      width="140"
      height="157"
      viewBox="0 0 140 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M72.7679 111C71.1222 113.505 69.9001 116.198 69.1214 119H13.5C11.567 119 10 120.567 10 122.5C10 124.433 11.567 126 13.5 126H68.1121C68.0861 127.688 68.2133 129.389 68.4974 131.09V131.087C68.9673 133.899 69.8474 136.553 71.0638 139L8.06332 139C3.61006 139 0 135.52 0 131.227V8.50008C0 4.20715 3.61006 0.727051 8.06332 0.727051L104.937 0.727087C109.39 0.727087 113 4.20718 113 8.50012V101.228C107.259 98.0963 100.424 96.746 93.4199 97.8343C88.1107 98.6602 83.2349 100.792 79.1774 104H13.5C11.567 104 10 105.567 10 107.5C10 109.433 11.567 111 13.5 111H72.7679ZM13.5 74.0001C11.567 74.0001 10 75.5671 10 77.5001C10 79.4331 11.567 81.0001 13.5 81.0001H99.5C101.433 81.0001 103 79.4331 103 77.5001C103 75.5671 101.433 74.0001 99.5 74.0001H13.5ZM10 92.5001C10 90.5671 11.567 89.0001 13.5 89.0001H99.5C101.433 89.0001 103 90.5671 103 92.5001C103 94.4331 101.433 96.0001 99.5 96.0001H13.5C11.567 96.0001 10 94.4331 10 92.5001ZM18 12.0001C13.5817 12.0001 10 15.5818 10 20.0001V58.0001C10 62.4184 13.5817 66.0001 18 66.0001H95C99.4183 66.0001 103 62.4184 103 58.0001V20.0001C103 15.5818 99.4183 12.0001 95 12.0001H18Z"
        fill="#383838"
      />
      <path
        d="M112.956 126.651C112.44 126.651 112.022 126.198 112.022 125.637V115.866C112.022 115.308 112.44 114.852 112.956 114.852C113.472 114.852 113.89 115.306 113.89 115.866V125.637C113.89 126.195 113.472 126.651 112.956 126.651Z"
        fill="#565656"
      />
      <path
        d="M86.9196 140.186C86.3672 140.186 85.9171 139.769 85.9171 139.254C85.9171 138.74 86.3672 138.322 86.9196 138.322H98.5839C99.1362 138.322 99.5863 138.74 99.5863 139.254C99.5863 139.769 99.1362 140.186 98.5839 140.186H86.9196Z"
        fill="#565656"
      />
      <path
        d="M104.009 140.186C103.493 140.186 103.074 139.769 103.074 139.254C103.074 138.74 103.493 138.322 104.009 138.322H105.228C105.726 138.322 106.235 138.264 106.736 138.15L106.77 138.143L106.802 138.131C106.833 138.119 106.865 138.109 106.899 138.099C108.841 137.592 110.442 136.281 111.293 134.5C111.371 134.335 111.437 134.185 111.495 134.032L111.529 133.935C111.77 133.279 111.909 132.597 111.938 131.906L111.989 130.692C112.011 130.192 112.427 129.799 112.936 129.799H112.977C113.233 129.811 113.466 129.918 113.639 130.103C113.809 130.287 113.897 130.523 113.887 130.77L113.836 131.983C113.736 134.338 112.705 136.488 110.931 138.034C109.361 139.422 107.337 140.184 105.225 140.184H104.006L104.009 140.186Z"
        fill="#565656"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M102.952 148.249C91.3255 150.488 80.0462 142.847 77.8101 131.223C75.5715 119.596 83.2123 108.316 94.8368 106.08C106.464 103.844 117.743 111.483 119.979 123.107C122.218 134.734 114.577 146.013 102.952 148.249ZM74.1297 131.93C76.7563 145.585 90.0027 154.554 103.657 151.927C108.834 150.931 113.337 148.409 116.791 144.917L133.361 156.756C133.653 156.964 134.015 157.045 134.368 156.977H134.373C134.723 156.909 135.03 156.702 135.224 156.404L138.869 151.407C139.258 150.81 139.095 150.011 138.504 149.613L121.844 137.589C123.939 132.992 124.68 127.727 123.655 122.399C121.028 108.745 107.782 99.7755 94.127 102.402C80.472 105.029 71.5031 118.275 74.1297 131.93Z"
        fill="#ABABAB"
      />
    </svg>
  </div>
  <div class="flex w-1/3 flex-col gap-y-small">
    <h1 class="font-x-large-600">Your account has not yet been approved</h1>
    <p class="font-small-500">
      Access to this section of the platform is restricted only to approved
      accounts. To check the latest status of your application, please visit the
      <a class="text-general-hyperlink" href="#/manage/">Account Management</a> section.
    </p>
  </div>
</div>
