<script lang="ts">
  let cssClass = ""
  export { cssClass as class }
  export let title = ""
</script>

<span
  class="table-header-cell overflow-hidden text-ellipsis whitespace-nowrap {cssClass}"
  role="columnheader"
  {title}
>
  <slot />
</span>
