<script lang="ts">
  import type { Account } from "@tastyworks/tastyworks-api"
  import type { Writable } from "svelte/store"
  import IgAccountsTable from "./IgAccountsTable.svelte"
  import IgAccountsTableBody from "./IgAccountsTableBody.svelte"
  import IgAccountsTableBodyCell from "./IgAccountsTableBodyCell.svelte"
  import IgAccountsTableBodyRow from "./IgAccountsTableBodyRow.svelte"
  import IgAccountsTableHeader from "./IgAccountsTableHeader.svelte"
  import IgAccountsTableHeaderCell from "./IgAccountsTableHeaderCell.svelte"

  export let accounts: Account[]
  export let currentAccountNumber: Writable<string | null>
  export let tableHeader = "US options and futures"

  const headers = [tableHeader]

  function handleClick(account) {
    if (account.accountNumber !== $currentAccountNumber) {
      $currentAccountNumber = account.accountNumber
    }
  }
</script>

<IgAccountsTable
  rowCount={accounts.length}
  label="IG/Tasty Accounts"
  name="ig-cam-tasty-accounts-table"
>
  <IgAccountsTableHeader {headers}>
    <IgAccountsTableHeaderCell title={tableHeader}>
      {tableHeader}
    </IgAccountsTableHeaderCell>
  </IgAccountsTableHeader>
  <IgAccountsTableBody>
    {#each accounts as account, i}
      <IgAccountsTableBodyRow
        element="button"
        isActive={account.accountNumber === $currentAccountNumber}
        rowIndex={i}
        class="hover:cursor-pointer hover:bg-general-primary-background"
        onClick={() => handleClick(account)}
        cols={1}
      >
        <IgAccountsTableBodyCell class="flex flex-col">
          <span class="font-small-600 truncate text-left">
            {account.extAccountId
              ? `Account ID: ${account.extAccountId}`
              : account.nickname}
          </span>
          <span class="font-x-small-400 text-left">
            tastytrade ID: {account.accountNumber}
          </span>
        </IgAccountsTableBodyCell>
      </IgAccountsTableBodyRow>
    {/each}
  </IgAccountsTableBody>
</IgAccountsTable>
