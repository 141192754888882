<script lang="ts">
  import { HeaderBar, HeaderNav, MobileHeader } from "."
  import HamburgerButton from "./HamburgerButton.svelte"
  import { isDashboard, isTrading } from "/@/layout"
  import AdminInfo from "/@/layout/AdminInfo.svelte"
  import Logo from "/@/layout/Logo.svelte"
  import media from "/@/layout/media"
  import HelpButton from "/@/logged-in/HelpButton.svelte"
  import LogoutButton from "/@/logged-in/LogoutButton.svelte"
  import { NavItem } from "/@/logged-in/routes"
  import { isAdmin } from "/@lib/shared"

  export let navItems: NavItem[]
  export let showMenuButton = true
</script>

<HeaderBar>
  {#if $media.mobile || ($media.tablet && !$isTrading)}
    <MobileHeader {navItems} {showMenuButton} />
  {:else}
    <div
      class="h-full flex-row {$isDashboard
        ? 'inline-flex flex-row-reverse'
        : 'contents'}"
    >
      <div
        style:width="var(--left-sidebar-collapsed-width)"
        style:min-width="var(--left-sidebar-collapsed-width)"
        class:md:mr-2={!$isDashboard}
        class:ml-0.5={!$media.mobile}
        class="flex h-full items-center justify-center align-middle"
      >
        <HamburgerButton {showMenuButton} />
      </div>
      {#if isAdmin()}
        <AdminInfo />
      {:else}
        <Logo
          height="50%"
          class="mr-extra-large {$isDashboard && 'ml-medium'}"
        />
      {/if}
    </div>
    {#if !isAdmin()}
      <HeaderNav {navItems} />
    {/if}
    <slot name="left">
      <div class="flex-1" />
    </slot>

    <slot name="right" />
    <HelpButton />
    {#if !isAdmin()}
      <LogoutButton />
    {/if}
  {/if}
</HeaderBar>
