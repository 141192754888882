<script>
  import Icons from "@tastyworks/icons"
  import { cn } from "@tastyworks/ui-library"
  import { fade } from "svelte/transition"
  import { leftSidebarCollapsed, showNewMenuButtonHighlight } from "/@/layout"

  export let showMenuButton = true
  let cssClass = ""
  export { cssClass as class }
</script>

<button
  class={cn(
    "hamburger-btn inline-flex h-full w-full flex-row items-center justify-center text-general-primary-text hover:bg-transparent",
    { hidden: !showMenuButton },
    cssClass
  )}
  on:pointerup={(e) => {
    // else we're in a race with the root layout left-sidebar-autocollapse
    e.stopPropagation()
    leftSidebarCollapsed.toggle()
    $showNewMenuButtonHighlight = false
  }}
>
  <!-- TODO: Remove the highlight once the feature is no longer "new" -->
  <!-- repeating ourself to force svelte to apply the transition when contents change -->
  <!-- this could be a case for Svelte 5 fragment? -->
  {#if $leftSidebarCollapsed}
    <span
      transition:fade
      class:highlight={$showNewMenuButtonHighlight}
      class="fixed"><Icons.hamburgerCollapsed class="h-5 w-5" /></span
    >
  {:else}
    <span
      transition:fade
      class:highlight={$showNewMenuButtonHighlight}
      class="fixed"><Icons.hamburgerExpanded class="h-5 w-5" /></span
    >
  {/if}
</button>

<style>
  .highlight {
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    box-shadow: 0 0 0 0
      rgb(from var(--color-background-color-theme-picker-dark-blue) r g b);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0
        rgb(
          from var(--color-background-color-theme-picker-dark-blue) r g b / 0.7
        );
    }

    70% {
      box-shadow: 0 0 0 var(--medium-spacing) rgba(0, 0, 0, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
</style>
