<script lang="ts">
  export let rowCount = -1
  export let name: string = undefined
  export let label = name
</script>

<div
  role="table"
  aria-label="{label} table"
  aria-rowcount={rowCount}
  class="{name} accounts-table flex flex-col gap-y-double-extra-small"
>
  <slot />
</div>
