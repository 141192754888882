
  import { isProduction } from "/@/environment/production-check"
  import { readable } from "svelte/store"
  import { isZVAReady } from "/@/logged-in/util"

  const helpChatScript = document.createElement("script")
// ZVA Script
helpChatScript.type = "text/javascript"
helpChatScript.async = true
helpChatScript.src = "https://us01ccistatic.zoom.us/us01cci/web-sdk/chat-client.js"
helpChatScript.setAttribute("data-apikey", "1SzzftE4Tx2wM3XUSkcleA")
helpChatScript.setAttribute("data-env", "us01")

window.addEventListener("zoomCampaignSdk:ready", () => {
    isZVAReady.set(true)
})

// ZVA handoff script
//Add if you need to hide Salesforce widget
//ZVA Chat By Event Script passing transcript, URL and Origin [set as a Custom Variable]
window.addEventListener('support_handoff', (e) => { 
    var isChatInProgress = localStorage.getItem("isChatting");
    localStorage.setItem("isChatting", true);
    //Example setting Origin [set as a ZVA Custom Variable]
    const origin = e.detail.tags[0];
    let sendTranscript='';
    let transcript = [];
    e.detail.transcript.map(x => {
        transcript.push(x.from + ":" + x.message);
    });
    transcript.forEach((el, index) => {
        const trimmedEl = el.replace(/\n/g, ''); 
        sendTranscript += trimmedEl;
        if (index !== transcript.length - 1) {
            sendTranscript += '\n';
        }
    });
    document.querySelector(".embeddedServiceHelpButton button")?.click();
    //You can adjust this time to influence the time it takes for ZVA to close before launching Salesforce 
    setTimeout(()=>window.zoomCampaignSdk.hide(), 3000);
    const caseRecordTypeId =  {
        "label":"RecordTypeId",           
        "value":"0128d000000d3ml",
        "displayToAgent":false
    }
    const contactFirstName = {
        "label":"First Name",
        "transcriptFields":[ "TT_First_Name__c" ],
        "displayToAgent":true
    }
    const contactLastName =  {
        "label":"Last Name",
        "transcriptFields":[ "TT_Last_Name__c" ],
        "displayToAgent":true
    }
    const contactEmail =  {
        "label":"Email",            
        "transcriptFields":[ "TT_Email__c" ],
        "displayToAgent":true                              
    }
    // Salesforce Prechat      
    if(window.embedded_svc.settings.extraPrechatFormDetails === undefined) {
        embedded_svc.snippetSettingsFile.extraPrechatInfo = 
        [
        {
            "entityName": "Contact",
            "showOnCreate": true,
            "linkToEntityName": "Case",
            "linkToEntityField": "ContactId",
            "saveToTranscript": "ContactId",
            "entityFieldMaps" : 
            [
            {
                "doCreate":true,
                "doFind":false,
                "fieldName":"FirstName",
                "isExactMatch":false,
                "label":"First Name"
            }, 
            {
                "doCreate":true,
                "doFind":false,
                "fieldName":"LastName",
                "isExactMatch":false,
                "label":"Last Name"
            }, 
            {
                "doCreate":true,
                "doFind":true,
                "fieldName":"Email",
                "isExactMatch":true,
                "label":"Email"
            }
            ],
        }, 
        {
            "entityName":"Case",
            "showOnCreate": true,
            "saveToTranscript": "CaseId",
            "entityFieldMaps": 
            [
            {
                "isExactMatch": false,
                "fieldName": "RecordTypeId",
                "doCreate": true,
                "doFind": false,
                "label": "RecordTypeId"
            }
            ]
        }
        ];                          
        window.embedded_svc.settings.extraPrechatFormDetails = [contactFirstName, contactLastName, contactEmail, caseRecordTypeId];
    } else {
        var URLDetailDefined = false;
        window.embedded_svc.settings.extraPrechatFormDetails.forEach((el) => {
            if(el.label === 'URL From Form') {
                URLDetailDefined = true;
            }               
        });
    }                           
    callCloseButton();
    callEndChatButton();
    function callCloseButton() {                       
        let embeddedContainer = document.getElementsByTagName("embeddedservice-chat-header")[0];
        // Set up to listen for Salesforce widget closure and reload ZVA invitation on page - remove if not required
        setTimeout(()=>{
            if(embeddedContainer){                  
                embeddedContainer.getElementsByClassName("closeButton")[0].addEventListener("click", ()=>{
                    window.embedded_svc.liveAgentAPI.endChat();
                    localStorage.setItem("isChatting", false);
                    window.zoomCampaignSdk.show();
                    window.zoomCampaignSdk.close();
                });                                                         
            } else {
                callCloseButton();
            }
        },100)                                                   
    } 
    function callEndChatButton() {                                   
        let endChatButton = document.getElementsByClassName("endChatButton closeChatButton")[0];
        // Set up to listen for Salesforce widget closure and reload ZVA invitation on page - remove if not required
        setTimeout(()=>{                
            if(endChatButton){                                                        
                endChatButton.addEventListener("click", ()=>{
                    window.embedded_svc.liveAgentAPI.endChat();
                    localStorage.setItem("isChatting", false);
                    window.zoomCampaignSdk.show();
                    window.zoomCampaignSdk.close();
                });                                             
            } else {
                callEndChatButton();
            }
        },100)                                                   
    } 
  })
  document.body.appendChild(helpChatScript)
