<script lang="ts" context="module">
  import {
    CATCH_ALL,
    DASHBOARD,
    DASHBOARD_NAV_ITEM,
    MANAGE,
    MANAGE_NAV_ITEM,
    TRADING,
    TRADING_NAV_ITEM,
  } from "./routes"
  import { loadTradingSession } from "/@/logged-in/util"

  const navItems = [DASHBOARD_NAV_ITEM, TRADING_NAV_ITEM, MANAGE_NAV_ITEM]
</script>

<script lang="ts">
  import Router from "svelte-spa-router"
  import wrap from "svelte-spa-router/wrap"
  import { hasOpenAccounts } from "/@/account-management/store/account"
  import { Header } from "/@/layout"
  import { IG_APP } from "/@lib/shared"
  import IgHeader from "/@/layout/ig/header/IgHeader.svelte"
</script>

<Router
  routes={new Map([
    [
      TRADING.glob,
      wrap({
        asyncComponent: async () => {
          if ($hasOpenAccounts) {
            await loadTradingSession()
            return IG_APP
              ? import("/@/trading/ig/IgTradingHeader.svelte")
              : import("/@/trading/TradingHeader.svelte")
          }
          return import("/@/layout/header/Header.svelte")
        },
        props: { navItems },
      }),
    ],
    [
      MANAGE.glob,
      wrap({
        component: IG_APP ? IgHeader : Header,
        props: { navItems },
      }),
    ],
    [
      DASHBOARD.glob,
      wrap({
        asyncComponent: async () => {
          if ($hasOpenAccounts) {
            return IG_APP
              ? import("/@/dashboard/ig/IgDashboardHeader.svelte")
              : import("/@/dashboard/DashboardHeader.svelte")
          }
          return import("/@/layout/header/Header.svelte")
        },
        props: { navItems },
      }),
    ],
    [
      CATCH_ALL.glob,
      wrap({
        component: IG_APP ? IgHeader : Header,
        conditions: [() => $hasOpenAccounts],
        props: { navItems },
        userData: {
          hasOpenAccounts: $hasOpenAccounts,
        },
      }),
    ],
  ])}
/>
