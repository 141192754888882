<header
  class="
    flex
    max-w-[100vw]
    items-center
    justify-start
    bg-general-primary-surface
    md:pr-small
    mobile:pl-0"
  style:grid-area="header"
  style:z-index="var(--header-z-index)"
  style:height="calc(var(--vertical-meter) * 1.5)"
>
  <slot />
</header>
