import { Writable, writable } from "svelte/store"

import { fetchAll } from "./contentstack"

const ZERO_HASH_CONTENT_TYPE_KEY = "zerohash"

export enum ZeroHashContent {
  ZeroHash_Full_Disclosure,
  Zero_Hash_Trading_Notice,
}

export const zeroHashContentMap: Writable<Map<string, string> | null> =
  writable(null)

export async function loadZeroHashContent() {
  const toMapEntry = (entry: { disclaimer: string; identifier: string }) => [
    ZeroHashContent[entry.identifier],
    entry.disclaimer,
  ]
  const content = await fetchAll(ZERO_HASH_CONTENT_TYPE_KEY, toMapEntry)
  zeroHashContentMap.set(content)
}
