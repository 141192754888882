<script lang="ts" context="module">
  import { CATCH_ALL, TRADING, TRADING_NAV_ITEM } from "./routes"

  const navItems = [TRADING_NAV_ITEM]
</script>

<script lang="ts">
  import Router from "svelte-spa-router"
  import wrap from "svelte-spa-router/wrap"
  import { Header } from "/@/layout"
  import { loadAndSaveTradingSession } from "/@/logged-in/util"
</script>

<Router
  routes={new Map([
    [
      TRADING.glob,
      wrap({
        asyncComponent: async () => {
          await loadAndSaveTradingSession()
          return import("/@/trading/TradingHeader.svelte")
        },
        props: { navItems },
      }),
    ],
    [
      CATCH_ALL.glob,
      wrap({
        component: Header,
        props: { navItems },
      }),
    ],
  ])}
/>
