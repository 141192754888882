
    import {get} from "svelte/store"
    import { helpCenterPanelVisible } from "/@/layout"

    function closeHelpCenter() {
        if (get(helpCenterPanelVisible)) {
            helpCenterPanelVisible.set(false)
        }
    }
    window.addEventListener("beforeunload", closeHelpCenter)
