<script>
  import { appTwSession } from "/@lib/boulangerie"
  import { getAdminAccountNumbers } from "/@lib/shared"
  import { boulePropertyStore } from "/@/boule/index.js"

  const twUser = boulePropertyStore(appTwSession.twUserProperty())
</script>

<div class="admin-info ml-extra-small inline px-extra-small">
  <p>{$twUser?.email}</p>
  <p>{getAdminAccountNumbers()}</p>
</div>

<style>
  .admin-info {
    background-color: var(--color-background-button-review-and-send);
    font-size: var(--small-font-size);
  }
</style>
