<script context="module">
  export const INITIAL_PROMPT_KEY = "content.landingPagePrompt.initialPrompt"
  export const DECLINED_PROMPT_KEY = "content.landingPagePrompt.declinedPrompt"
  export const SETTINGS_PATH_KEY = "content.landingPagePrompt.settingsPath"
  export const ACCEPT_BUTTON_TEXT_KEY =
    "content.landingPagePrompt.acceptButtonText"
  export const DECLINE_BUTTON_TEXT_KEY =
    "content.landingPagePrompt.declineButtonText"
  export const CONFIRM_DECLINE_BUTTON_TEXT_KEY =
    "content.landingPagePrompt.confirmDeclineButtonText"
</script>

<script>
  import { createEventDispatcher } from "svelte"
  import { _ } from "svelte-i18n"
  import ModalDialog from "/@/layout/modal/ModalDialog.svelte"

  export let lightbox = true
  export let show

  const dispatch = createEventDispatcher()
  const height = "calc(var(--vertical-meter) * 9.75)"
  const width = "calc(var(--horizontal-meter) * 5)"
  const buttonWidth = "calc(var(--horizontal-meter) * 4.375)"
  const buttonHeight =
    "calc(var(--vertical-meter) + var(--extra-small-spacing))"

  let promptDeclined = false

  function decline() {
    promptDeclined = true
  }

  function confirmDecline() {
    dispatch("close", false)
  }

  function accept() {
    dispatch("close", true)
  }
</script>

<ModalDialog class="landing-page-prompt" {show} {lightbox} {height} {width}>
  <div slot="body" class="text-center">
    {#if !promptDeclined}
      <div class="initial-prompt font-large-500">
        {$_(INITIAL_PROMPT_KEY)}
      </div>
    {:else}
      <span class="declined-prompt font-small-400">
        {$_(DECLINED_PROMPT_KEY)}
      </span>
      <br />
      <span class="settings-path font-small-600 text-general-hyperlink">
        {$_(SETTINGS_PATH_KEY)}
      </span>
    {/if}
  </div>

  <div
    slot="actions"
    class="
      item-stretch
      mb-medium
      flex
      flex-col
      items-center
      justify-center
      gap-medium"
  >
    {#if !promptDeclined}
      <button
        class="accept font-medium-600 button button-confirmation"
        style:width={buttonWidth}
        style:height={buttonHeight}
        on:click={accept}
      >
        {$_(ACCEPT_BUTTON_TEXT_KEY)}
      </button>
      <button
        class="decline font-medium-600 button button-cancel"
        style:width={buttonWidth}
        style:height={buttonHeight}
        on:click={decline}
      >
        {$_(DECLINE_BUTTON_TEXT_KEY)}
      </button>
    {:else}
      <button
        class="confirm-decline font-medium-600 button"
        style:width={buttonWidth}
        style:height={buttonHeight}
        on:click={confirmDecline}
      >
        {$_(CONFIRM_DECLINE_BUTTON_TEXT_KEY)}
      </button>
    {/if}
  </div>
</ModalDialog>
