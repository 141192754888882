type AsyncFunc<T> = () => Promise<T>

export function lock<T>(task: AsyncFunc<T>): AsyncFunc<T | null> {
  let locked = false

  return async () => {
    if (locked) {
      return null
    }

    locked = true
    const value = await task()
    locked = false
    return value
  }
}
